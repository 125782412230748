<template>
  <div class="row">
    <a-row align="middle" class="form_title sm-mb">
      <a-col :span="4" class="form_title"> Язык: </a-col>
      <a-col :span="12">
        <!-- lang translit tabs -->
        <a-row align="middle" class="flex-baseline flex-wrap">
          <lang-tabs v-model="langTab" class="mb-1x" />
        </a-row>
      </a-col>
    </a-row>

    <a-row align="middle" class="flex-baseline">
      <a-col :span="4" class="form_title"> Название: </a-col>
      <a-col :span="18" class="sm-mb">
        <a-input
          v-model="item['title_' + getKey]"
          class="form_title-input"
          name="linkName"
          placeholder="Введите название"
        />
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="4" class="form_title" style="padding-top: 2px">
        Изображение:
      </a-col>
      <a-col :span="12" class="sm-mb">
        <a-button
          style="font-size: 13px"
          type="primary"
          @click="$refs.fileInput.click()"
        >
          <a-icon style="font-size: 16px" type="upload" /> Upload Image
        </a-button>

        <span v-if="!image_url" style="padding-left: 8px">
          Картинка не выбрана
        </span>

        <!--<span v-if="$v.file.$dirty && !$v.file.required" class="error-message"
          >Картинка не выбрана</span
        >-->

        <input
          ref="fileInput"
          class="UploadhideInput"
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/svg"
          @change="handleFileUpload"
        />
        <div class="committee-image">
          <img :src="image_url" alt="" />
        </div>
      </a-col>
    </a-row>

    <a-row class="form_title sm-mb">
      <a-col :span="4" />
      <a-col :span="12">
        <a-button
          v-if="item.id"
          style="margin-right: 10px"
          type="primary"
          @click="$emit('update', item)"
        >
          <a-icon type="check" />Сохранить
        </a-button>

        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить ?"
          @confirm="$emit('delete', item)"
        >
          <a-button type="danger"> <a-icon type="delete" /> Удалить </a-button>
        </a-popconfirm>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      langTab: "oz",
      image_url: ""
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  created() {
    // console.log("this.item from child component: ", this.item)
    if (this.item.photo) {
      this.image_url = this.item.photo
    }
    // console.log("this.image_url from child component: ", this.image_url)
  },
  methods: {
    handleFileUpload(e) {
      // console.log("from attachment vue", e.target.files[0])
      if (e.target.files && e.target.files.length !== 0) {
        this.image_url = URL.createObjectURL(e.target.files[0])
        this.item.binary_file = e.target.files[0]
        e.target.value = null
      }
    }
  },
  validations: {
    file: {
      required
    }
  }
}
</script>

<style scoped>
.row {
  border-bottom: 1px solid red;
}
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  max-height: 200px;
}
</style>
